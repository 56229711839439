/* img {
  border-radius: 10px;
  border-width: 2px;
  border-color: black;
} */

body {
  /* background: #101c2c; */
  overflow-x: hidden;
}

.top-navigation {
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 2000;
  /* color: #d6df29 !important; */
}

.dashboard-metric {
  border-radius: 10px !important;
  border: 1px solid #929292 !important;
  padding: 10px !important;
}

.chat-image {
  padding-top: 5px;
  padding-bottom: 5px;
}

/* https://stackoverflow.com/questions/76187282/react-resizeobserver-loop-completed-with-undelivered-notifications */
iframe#webpack-dev-server-client-overlay {
  display: none !important
}

/* @media screen and (min-width: 1006px) {
  .chat-feedback-s {
    position: absolute;
    bottom: 0;
  }

  .chat-feedback-xs {
    display: none;
  }
} */


/* @media screen and (max-width: 1005px) {
  .chat-feedback-s {
    display: none;
  }
} */


.turn-container {
  min-height: 415px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.turn-exit {
  opacity: 0;
  transition: opacity 1s;
}

.turn-exit-active {
  opacity: 0;
}

.turn-enter {
  opacity: 0;
}

.turn-enter-overlay {
  position: absolute;
  padding-right: 20px;
}

.turn-enter-active {
  opacity: 1;
  transition: opacity 1s;
  transition-delay: 1s;
}

.turn-cancel {
  display: none;
}

.session-item-first {
  padding: 0 10px;
  margin: 8px 0;
  border-left-width: 0;
  padding-left: 0;
}

.session-item-info {
  padding: 0 10px;
  margin: 8px 0;
  border-left: 2px solid var(--color-border-divider-default-7s2wjw, #e9ebed);
}

.escape-chat-box {
  /* place at bottom of container */
  /* position: absolute;
  width: 100%;
  bottom: 0; */
  margin: 0;
  padding: 0;
}

.chat-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
}

.chat-content {
  padding: 10px;
  overflow-y: auto;
  height: 90%;
}


.chat-turn-user {
  background-color: #f9fedc !important;
  border-radius: 0px;
  border: unset !important;
}

.chat-turn-assistant-text {
  border-radius: 0px;
  border: unset !important;
}